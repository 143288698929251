<template>
  <div ref="containergen" style="width:100%;height:100%" v-resize="onResize" class="pb-0">
    <VirtualCollection
      ref="virtualCollection"
      style="overflow-x: hidden !important;overflow-y: auto !important;"
      :cellSizeAndPositionGetter="cellSizeAndPositionGetter"
      :collection="records"
      :height="virheight"
      :width="virwidth"
      :scroll-to-bottom-range="500"
      @scrolled-to-bottom-range="$emit('reload')"
    >
      <view-mon
        v-if="collection == 'marc_mon'"
        slot="cell"
        slot-scope="props"
        :show-only-image="selectedView == 1"
        :record="props.data"
      />
      <view-ath v-else-if="collection == 'marc_ath'" slot="cell" slot-scope="props" :record="props.data" />
      <view-art v-else-if="collection == 'marc_art'" slot="cell" slot-scope="props" :record="props.data" />
    </VirtualCollection>
  </div>
</template>
<script>
import ViewMon from "./ViewMon"
import ViewAth from "./ViewAth"
import ViewArt from "./ViewArt"
export default {
  name: "DashboardDashboard",
  components: { ViewMon, ViewAth, ViewArt },
  props: {
    selectedView: {
      type: Number,
      default: 0
    },
    cellHeight: {
      type: Number,
      default: 280
    },
    cellWidth: {
      type: Number,
      default: 350
    },
    verticalGap: {
      type: Number,
      default: 20
    },
    horizontallGap: {
      type: Number,
      default: 20
    },
    collection: {
      type: String,
      default: "marc_mon"
    },
    records: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      virwidth: 300,
      virheight: 500
    }
  },
  watch: {
    selectedView() {
      this.resetCollection()
    }
  },
  mounted() {
    this.$nextTick(() => {
      setTimeout(this.onResize, 100)
      //this.onResize()
    })
  },
  methods: {
    resetCollection() {
      this.$refs.virtualCollection.$el.scrollTop = 0
      this.$refs.virtualCollection.resetCollection()
    },
    onResize() {
      this.virwidth = this.$refs.containergen.clientWidth
      this.virheight = window.innerHeight - this.$refs.containergen.getBoundingClientRect().y - 15
    },
    cellSizeAndPositionGetter(item, index) {
      // compute size and position
      if (this.selectedView == 1) {
        let cols = parseInt(this.virwidth / (135 + this.horizontallGap))
        if (cols < 1) {
          cols = 1
        }
        return {
          width: (this.virwidth - cols * this.horizontallGap) / cols,
          height: 210,
          x: (index % cols) * ((this.virwidth - cols * this.horizontallGap) / cols + this.horizontallGap),
          y: parseInt(index / cols) * 210
        }
      }
      let cols = parseInt(this.virwidth / this.cellWidth)
      if (this.selectedView != 0 || cols < 1) {
        cols = 1
      }
      return {
        width: (this.virwidth - cols * this.horizontallGap) / cols,
        height: this.cellHeight,
        x: (index % cols) * ((this.virwidth - cols * this.horizontallGap) / cols + this.horizontallGap),
        y: parseInt(index / cols) * (this.cellHeight + this.verticalGap)
      }
    }
  }
}
</script>
