<template>
  <v-hover>
    <template v-slot:default="{ hover }">
      <v-img
        v-if="showOnlyImage && record['fields.856.link']"
        class="d-float-left mt-3"
        :src="baseURL + 'file_stream/?f=' + record['fields.856.link']"
        lazy-src="/image/default/mon/default.png"
        contain
        height="205"
      />
      <v-img
        v-else-if="showOnlyImage"
        class="d-float-left mt-3"
        src="/image/default/mon/default.png"
        contain
        height="205"
      />
      <v-card
        v-else
        class="mt-0 mb-0"
        width="100%"
        height="280px"
        :elevation="hover ? 7 : 3"
        tile
        @click="openDetails(record)"
      >
        <v-card-title style="position: relative;height:65px;overflow:hidden" class="align-start grey lighten-4 d-flex">
          <span class="text-h5">{{ record["fields.200.a.val"] }}</span>
          <v-btn
            v-if="hover && showDelete"
            color="red"
            icon
            style="position: absolute;top:0px;right:5px;float:right"
            class="float-left mr-n3 mt-n2"
            @click.prevent.stop="$emit('delete')"
          >
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-divider />
          <div class="d-inline-flex">
            <v-img
              v-if="record['fields.856.link']"
              class="d-float-left mt-1"
              :src="baseURL + 'file_stream/?f=' + record['fields.856.link']"
              lazy-src="/image/default/mon/default.png"
              contain
              width="135"
              height="205"
            />
            <v-img
              v-else
              class="d-float-left mt-3"
              src="/image/default/mon/default.png"
              contain
              width="135"
              height="205"
            />
            <div style="height:200px;overflow:hidden" class="ml-3 pt-3 text-body-2">
              <div v-if="record['fields.700.a.val']" class="mb-1">
                <span>
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-icon :color="$config.color" class="mr-1" v-on="on">mdi-account</v-icon>
                    </template>
                    <span>{{ $t("Autor principal") }}</span>
                  </v-tooltip>
                  {{ record["fields.700.a.val"] }}
                </span>
              </div>
              <div v-if="record['fields.200.f.val']" class="mb-1 ">
                <span>
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-icon :color="$config.color" class="mr-1" v-on="on">mdi-account-plus</v-icon>
                    </template>
                    <span>{{ $t("Prima menţ. de resp.") }}</span>
                  </v-tooltip>
                  {{ record["fields.200.f.val"] }}
                </span>
              </div>
              <div v-if="record['fields.210.a.val']" class="mb-1">
                <span>
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-icon :color="$config.color" class="mr-1" v-on="on">mdi-earth</v-icon>
                    </template>
                    <span>{{ $t("Loc publicare") }}</span>
                  </v-tooltip>
                  {{ record["fields.210.a.val"] }}
                </span>
              </div>
              <div v-if="record['fields.210.c.val']" class="mb-1">
                <span>
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-icon :color="$config.color" class="mr-1" v-on="on">mdi-alpha-e-box</v-icon>
                    </template>
                    <span>{{ $t("Editura") }}</span>
                  </v-tooltip>
                  {{ record["fields.210.c.val"] }}
                </span>
              </div>
              <div v-if="record['fields.210.d.val']" class="mb-1">
                <span>
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-icon :color="$config.color" class="mr-1" v-on="on">mdi-calendar</v-icon>
                    </template>
                    <span>{{ $t("Data publicării") }}</span>
                  </v-tooltip>
                  {{ record["fields.210.d.val"] }}
                </span>
              </div>
              <div v-if="record['fields.606.a.val']" class="mb-1">
                <span>
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-icon :color="$config.color" class="mr-1" v-on="on">mdi-label</v-icon>
                    </template>
                    <span>{{ $t("Subiect") }}</span>
                  </v-tooltip>
                  {{ record["fields.606.a.val"] }}
                </span>
              </div>
              <div v-if="record['fields.010.a.val']" class="mb-1">
                <span>
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-icon :color="$config.color" class="mr-1" v-on="on">mdi-numeric-9-box-multiple-outline</v-icon>
                    </template>
                    <span>{{ $t("ISBN") }}</span>
                  </v-tooltip>
                  {{ record["fields.010.a.val"] }}
                </span>
              </div>
              <div v-if="record['fields.675.a.val']" class="mb-1">
                <span>
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-icon :color="$config.color" class="mr-1" v-on="on">mdi-vector-link</v-icon>
                    </template>
                    <span>{{ $t("Număr (indice) CZU") }}</span>
                  </v-tooltip>
                  {{ record["fields.675.a.val"] }}
                </span>
              </div>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </template>
  </v-hover>
</template>
<script>
import axios from "../../../../plugins/axios"
export default {
  name: "DashboardDashboard",
  components: {},
  props: {
    category: {
      type: String,
      default: ""
    },
    showDelete: {
      type: Boolean,
      default: false
    },
    showOnlyImage: {
      type: Boolean,
      default: false
    },
    record: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      selectedView: 2,
      records: []
    }
  },
  computed: {
    baseURL() {
      return axios.defaults.baseURL
    }
  },
  methods: {
    openDetails(record) {
      this.$log("openDetails ", record)
      this.$router.push("/mon/" + record._id)
    }
  }
}
</script>
<style></style>
