var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"mt-0 mb-1",attrs:{"height":"280px","elevation":hover ? 7 : 3},on:{"click":function($event){return _vm.openDetails(_vm.record)}}},[_c('v-card-title',{staticClass:"align-start  grey lighten-4",staticStyle:{"height":"65px","overflow":"hidden"}},[_c('span',{staticClass:"text-truncate  text-h5"},[_vm._v(_vm._s(_vm.record.title))]),(hover && _vm.showDelete)?_c('v-btn',{staticClass:"float-left mr-n3 mt-n2",staticStyle:{"position":"absolute","top":"0px","right":"5px","float":"right"},attrs:{"color":"red","icon":""},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.$emit('delete')}}},[_c('v-icon',[_vm._v("mdi-delete")])],1):_vm._e()],1),_c('v-card-text',[_c('v-divider'),_c('div',{staticClass:"d-inline-flex"},[_c('div',{staticClass:"ml-3 mt-3  text-body-2",staticStyle:{"height":"200px","overflow":"hidden"}},[(_vm.record.ctg)?_c('div',{staticClass:"mb-1"},[_c('span',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-1",attrs:{"color":_vm.$config.color}},on),[_vm._v("mdi-rhombus-split")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("Categorie")))])]),_vm._v(" "+_vm._s(_vm.$t(_vm.record.ctg))+" ")],1)]):_vm._e(),(_vm.record['fields.210.a.val'])?_c('div',{staticClass:"mb-1"},[_c('span',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-1",attrs:{"color":_vm.$config.color}},on),[_vm._v("mdi-earth")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("Loc publicare")))])]),_vm._v(" "+_vm._s(_vm.record["fields.210.a.val"])+" ")],1)]):_vm._e(),(_vm.record['fields.210.c.val'])?_c('div',{staticClass:"mb-1"},[_c('span',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-1",attrs:{"color":_vm.$config.color}},on),[_vm._v("mdi-alpha-e-box")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("Editura")))])]),_vm._v(" "+_vm._s(_vm.record["fields.210.c.val"])+" ")],1)]):_vm._e(),(_vm.record['fields.210.d.val'])?_c('div',{staticClass:"mb-1"},[_c('span',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-1",attrs:{"color":_vm.$config.color}},on),[_vm._v("mdi-calendar")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("Data publicării")))])]),_vm._v(" "+_vm._s(_vm.record["fields.210.d.val"])+" ")],1)]):_vm._e(),(_vm.record['fields.606.a.val'])?_c('div',{staticClass:"mb-1"},[_c('span',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-1",attrs:{"color":_vm.$config.color}},on),[_vm._v("mdi-label")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("Subiect")))])]),_vm._v(" "+_vm._s(_vm.record["fields.606.a.val"])+" ")],1)]):_vm._e(),(_vm.record['fields.610.a.val'])?_c('div',{staticClass:"mb-1"},[_c('span',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-1",attrs:{"color":_vm.$config.color}},on),[_vm._v("mdi-label")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("Subiecte")))])]),_vm._v(" "+_vm._s(_vm.record["fields.610.a.val"])+" ")],1)]):_vm._e(),(_vm.record['fields.010.a.val'])?_c('div',{staticClass:"mb-1"},[_c('span',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-1",attrs:{"color":_vm.$config.color}},on),[_vm._v("mdi-numeric-9-box-multiple-outline")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("ISBN")))])]),_vm._v(" "+_vm._s(_vm.record["fields.010.a.val"])+" ")],1)]):_vm._e(),(_vm.record['fields.675.a.val'])?_c('div',{staticClass:"mb-1"},[_c('span',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-1",attrs:{"color":_vm.$config.color}},on),[_vm._v("mdi-vector-link")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("Număr (indice) CZU")))])]),_vm._v(" "+_vm._s(_vm.record["fields.675.a.val"])+" ")],1)]):_vm._e(),(_vm.record['fields.300.a.val'])?_c('div',{staticClass:"mb-1"},[_c('span',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-1",attrs:{"color":_vm.$config.color}},on),[_vm._v("mdi-clipboard-text")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("Note")))])]),_vm._v(" "+_vm._s(_vm.record["fields.300.a.val"])+" ")],1)]):_vm._e()])])],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }