<template>
  <v-hover>
    <template v-slot:default="{ hover }">
      <v-card class="mt-0 mb-1" height="280px" :elevation="hover ? 7 : 3" @click="openDetails(record)">
        <v-card-title style="height:65px;overflow:hidden" class="align-start  grey lighten-4">
          <span class="text-truncate  text-h5">{{ record.title }}</span>
          <v-btn
            v-if="hover && showDelete"
            color="red"
            icon
            style="position: absolute;top:0px;right:5px;float:right"
            class="float-left mr-n3 mt-n2"
            @click.prevent.stop="$emit('delete')"
          >
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-divider />
          <div class="d-inline-flex">
            <div style="height:200px;overflow:hidden" class="ml-3 mt-3  text-body-2">
              <div v-if="record.ctg" class="mb-1">
                <span>
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-icon :color="$config.color" class="mr-1" v-on="on">mdi-rhombus-split</v-icon>
                    </template>
                    <span>{{ $t("Categorie") }}</span>
                  </v-tooltip>
                  {{ $t(record.ctg) }}
                </span>
              </div>
              <div v-if="record['fields.210.a.val']" class="mb-1">
                <span>
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-icon :color="$config.color" class="mr-1" v-on="on">mdi-earth</v-icon>
                    </template>
                    <span>{{ $t("Loc publicare") }}</span>
                  </v-tooltip>
                  {{ record["fields.210.a.val"] }}
                </span>
              </div>
              <div v-if="record['fields.210.c.val']" class="mb-1">
                <span>
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-icon :color="$config.color" class="mr-1" v-on="on">mdi-alpha-e-box</v-icon>
                    </template>
                    <span>{{ $t("Editura") }}</span>
                  </v-tooltip>
                  {{ record["fields.210.c.val"] }}
                </span>
              </div>
              <div v-if="record['fields.210.d.val']" class="mb-1">
                <span>
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-icon :color="$config.color" class="mr-1" v-on="on">mdi-calendar</v-icon>
                    </template>
                    <span>{{ $t("Data publicării") }}</span>
                  </v-tooltip>
                  {{ record["fields.210.d.val"] }}
                </span>
              </div>
              <div v-if="record['fields.606.a.val']" class="mb-1">
                <span>
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-icon :color="$config.color" class="mr-1" v-on="on">mdi-label</v-icon>
                    </template>
                    <span>{{ $t("Subiect") }}</span>
                  </v-tooltip>
                  {{ record["fields.606.a.val"] }}
                </span>
              </div>
              <div v-if="record['fields.610.a.val']" class="mb-1">
                <span>
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-icon :color="$config.color" class="mr-1" v-on="on">mdi-label</v-icon>
                    </template>
                    <span>{{ $t("Subiecte") }}</span>
                  </v-tooltip>
                  {{ record["fields.610.a.val"] }}
                </span>
              </div>
              <div v-if="record['fields.010.a.val']" class="mb-1">
                <span>
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-icon :color="$config.color" class="mr-1" v-on="on">mdi-numeric-9-box-multiple-outline</v-icon>
                    </template>
                    <span>{{ $t("ISBN") }}</span>
                  </v-tooltip>
                  {{ record["fields.010.a.val"] }}
                </span>
              </div>
              <div v-if="record['fields.675.a.val']" class="mb-1">
                <span>
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-icon :color="$config.color" class="mr-1" v-on="on">mdi-vector-link</v-icon>
                    </template>
                    <span>{{ $t("Număr (indice) CZU") }}</span>
                  </v-tooltip>
                  {{ record["fields.675.a.val"] }}
                </span>
              </div>
              <div v-if="record['fields.300.a.val']" class="mb-1">
                <span>
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-icon :color="$config.color" class="mr-1" v-on="on">mdi-clipboard-text</v-icon>
                    </template>
                    <span>{{ $t("Note") }}</span>
                  </v-tooltip>
                  {{ record["fields.300.a.val"] }}
                </span>
              </div>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </template>
  </v-hover>
</template>
<script>
import axios from "../../../../plugins/axios"
export default {
  name: "DashboardDashboard",
  components: {},
  props: {
    record: {
      type: Object,
      default: () => {}
    },
    showDelete: {
      type: Boolean,
      default: false
    },
    category: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      selectedView: 2,
      records: []
    }
  },
  computed: {
    baseURL() {
      return axios.defaults.baseURL
    }
  },
  methods: {
    openDetails(record) {
      this.$log("openDetails ", record)
      this.$router.push("/art/" + record._id)
    }
  }
}
</script>
<style></style>
